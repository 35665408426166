import Embed from "./embed";

export function embed(options, style) {
  const embed = new Embed();

  embed.attach(options, style);
}

/**
 * ChatWidgets.embed({enterprise: "SHOPPING_TIJUCA", message: "Olá, precisa de ajuda?", color: "#212528"})
 */
