// color=%2523212528&amp;empreendimento=SHOPPING_TIJUCA&amp;device=desktop&amp;tela=false
class Embed {
  constructor() {
    this.url = env?.PUBLIC_URL;

    if (!this.url) {
      throw new Error(
        "Error 404: The requested URL could not be found on the service.",
      );
    }
  }

  buildFragment(data) {
    const bytes = new TextEncoder().encode(JSON.stringify(data));
    const binString = Array.from(bytes, (byte) =>
      String.fromCodePoint(byte),
    ).join("");
    const buffer = btoa(binString);

    return buffer.replace(/\+/g, "-").replace(/\//g, "_");
  }

  styled(data) {
    const values = Object.keys(data).map(
      (index) => `${index.replace(/([A-Z])/, "-$1")}: ${data[index]};`,
    );

    return values.join(" ");
  }

  createObject(url, style = {}) {
    const allowedOrigin = new URL(this.url).origin;
    const horizontalSpace = 0;
    const verticalSpace = 8;
    const styled = {
      overflow: "hidden",
      position: "fixed",
      zIndex: 10000010,
      bottom: "calc(1em - 8px)",
      right: "8px",
      background: "transparent",
      // minWidth: "290px",
      // maxWidth: "100%",
      //
      // width: "27%",
      // height: "90%",
      // border: "medium",
      // overflow: "hidden",
      // position: "fixed",
      // zIndex: 10000010,
      // bottom: "calc(1em - 8px)",
      // right: 0,
      // background: "transparent",
      minWidth: "290px",
      minHeight: "75px",
      maxWidth: "100%",
      maxHeight: "100%",
      border: "unset",
      //
      ...style,
    };
    const frameElm = document.createElement("iframe");

    frameElm.setAttribute("id", "chatbox-embed");
    frameElm.setAttribute("src", url);
    frameElm.setAttribute("style", this.styled(styled));
    frameElm.setAttribute("width", 0);
    frameElm.setAttribute("height", 0);
    window.addEventListener("message", ({ data = {}, origin }) => {
      if (origin !== allowedOrigin) {
        console.warn(
          `Warning: Message from unauthorized origin '${origin}' blocked.`,
        );
        return;
      }

      switch (data.type) {
        case "_COMPONENT_DIMENSIONS":
          const { dimensions, width = null, height = null } = data;
          const _width = width ?? dimensions.width + horizontalSpace * 2;
          const _height = height ?? dimensions.height + verticalSpace * 2;

          frameElm.setAttribute("width", _width);
          frameElm.setAttribute("height", _height);
          break;
        default:
          console.warn(
            `Warning: The type '${data.type}' is not recognized. Please check and provide a valid type.`,
          );
      }
    });

    return frameElm;
  }

  attach(options = {}, style = {}) {
    const fragment = this.buildFragment(options);
    const elm = this.createObject(`${this.url}#${fragment}`, style);

    document.body.appendChild(elm);
  }
}

export default Embed;
